import $ from 'jquery'

// Navbar

function toggle_mobile_menu() {
  $('.mobile-nav').toggle(200);
}

const $mobile_menu_toggle = $('#mobile-menu-toggle')
if ($mobile_menu_toggle.length) {
  $mobile_menu_toggle.click(() => {
    toggle_mobile_menu();
  })
  $('#close-menu-toggle').click(() => {
    toggle_mobile_menu();
  })
}

// Dropdown Menu Toggle
const $dropdownToggle = $('a[data-toggle="collapse"]');

if ($dropdownToggle.length) {
  $dropdownToggle.click(function(e) {
    e.preventDefault();
    var target = $(this).attr('data-target');

    $(target).toggleClass('open');

    if ($(target).hasClass('open')) {
      $(target).css('max-height', '500px');
    } else {
      $(target).css('max-height', '0');
    }

    $(this).find('span').toggleClass('rotate');

  });
}

const STICKY_NAV_EXCLUDE_PATHS = ['/find-a-tutor/']
if (!STICKY_NAV_EXCLUDE_PATHS.some(path => window.location.pathname.includes(path))) {
  function update_nav() {
    const $nav = $('nav')
    if (window.pageYOffset > 0) {
      $nav.addClass('sticky-nav')
    } else {
      $nav.removeClass('sticky-nav')
    }
  }

  update_nav()
  $(window).scroll(() => {
    update_nav()
  })
}

// Intercom Snippet

const APP_ID = 'nq7o3z6g';
window.intercomSettings = {
  app_id: APP_ID
};

const IC_EXCLUDE_PATHS = ['/find-a-tutor/']
if (!IC_EXCLUDE_PATHS.some(path => window.location.pathname.includes(path))) {
  const intercomLoader = () => {
    (function () {
      var w = window
      var ic = w.Intercom
      if (typeof ic === "function") {
        ic('reattach_activator')
        ic('update', w.intercomSettings)
      } else {
        var d = document
        var i = function () {
          i.c(arguments)
        };
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        };
        w.Intercom = i
        var l = function () {
          setTimeout(function(){
            var s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/' + APP_ID
            var x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }, 4000)
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })();
  }
  intercomLoader();
}

// Footer

const change_region = () => {
  const gb_phone = '020 3966 1863'
  const us_phone = '763 200 6863'
  const gbl_phone = '020 3966 1863'

  const us_open = '4am - 1pm (EST)'
  const gb_open = '9am - 5:30pm'
  const gbl_open = '9am - 5:30pm (BST)'

  let phone = gbl_phone
  let open_times = gbl_open
  let request = new XMLHttpRequest()
  request.responseType = 'json'
  request.open('GET', 'https://import.tutorcruncher.com/api/country/', true)
  request.onload = () => {
    const country = request.response['country_code'].toLowerCase()
    if (country === 'us' || country === 'ca') {
      phone = us_phone
      open_times = us_open
    } else if (country === 'gb') {
      phone = gb_phone
      open_times = gb_open
    }
    for (let el of document.getElementsByClassName('phone-number')) {
      el.href = 'tel:' + phone
      el.innerText = phone
    }
    for (let el of document.getElementsByClassName('opening-times')) {
      el.innerText = open_times
    }
  }
  request.send()
}

change_region()


// Tooltips

$('.tc-tooltip-keyword').each((i, el) => {
  $(el).find('.tc-tooltip').css('transform', `translateX(-${85 + ($(el).width() / 2)}px)`)
})

// Media (Blog Images, YouTube Videos)

function resize_youtube_video_iframes() {
  $('.video').each((i, el) => {
    const $el = $(el)
    $el.css('height', Math.round(($el.width() * (9 / 16)) - 1))
  })
}

$(window).on('resize', () => {
  resize_youtube_video_iframes()
})
resize_youtube_video_iframes()

// FAQs

const FAQ_PAGES = ['/tutor-management-software', '/tutoring-online', '/starting-tutor-business', '/getting-paid', '/test-prep-business-management-software']
if (FAQ_PAGES.some(path => window.location.pathname.includes(path))) {
  $('body').click(e => {
    const $el = $(e.target)
    if ($el.is('a')) {
      return
    }

    let $faq
    if ($el.hasClass('faq')) {
      $faq = $el
    } else {
      $faq = $el.parents('.faq')
    }

    if ($faq) {
      if ($faq.hasClass('active')) {
        $faq.removeClass('active')
        $faq.children('.answer').display = 'none'
        $faq.children('.question').child('.fa-angle-up').display = 'none'
        $faq.children('.question').child('.fa-angle-down').display = 'block'
      } else {
        $faq.addClass('active')
        $faq.children('.answer').display = 'block'
        $faq.children('.question').child('.fa-angle-up').display = 'block'
        $faq.children('.question').child('.fa-angle-down').display = 'none'
      }
    }
  })
}

$('#ParentContainer').scroll(function() {
  $('#FixedDiv').animate({top:$(this).scrollTop()},100,"linear");
})

